<template>
  <div class="relative" :class="[`ag-col-span-${block.col_span}`, `ag-row-span-${block.row_span}`]">
    <LazyCustomiseBlockControl
      v-if="isAuthorisedUser && customisationsEnabled && block.customisable && ['image', 'text'].includes(block.type) && !minimalUI"
      :block="block"
    />
    <component
      :is="getBlockComponent(block)"
      :block="block"
      :customisations-enabled="customisationsEnabled"
      :customisations="customisations"
      :class="[
        'ag-block relative h-full overflow-hidden',
        `ag-block--${getClass(block)}`,
        {
          'ag-block--has-featured-image': block.item.featured_image && !block.item.embed,
        },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
  isAuthorisedUser: boolean;
  customisationsEnabled: LandingPage["partner"]["customisations_enabled"];
  customisations: Customisations;
}>();

const { minimalUI } = useLayout();

const blockComponents = {
  content: resolveComponent("LazyGridContentBlock"),
  cta: resolveComponent("LazyCTABlock"),
  heading: resolveComponent("LazyHeadingBlock"),
  image: resolveComponent("LazyImageBlock"),
  itinerary: resolveComponent("LazyItineraryBlock"),
  note: resolveComponent("LazyNoteBlock"),
  text: resolveComponent("LazyTextBlock"),
  featuredContentBlock: resolveComponent("LazyFeaturedContentBlock"),
  gridFeaturedContentBlock: resolveComponent("LazyGridFeaturedContentBlock"),
  modernelloContentBlock: resolveComponent("LazyModernelloContentBlock"),
};

const getClass = (block: Block) => {
  if (
    block.type === "content" &&
    ((props.customisations.content_layout === 2 && block.col_span === 2) || (props.customisations.content_layout === 3 && block.col_span === 6)) &&
    props.customisations.theme !== "modernello"
  ) {
    return "featured-content";
  }

  return block.type;
};

const getBlockComponent = (block: Block) => {
  let type = block.type;

  if (block.type === "content" && props.customisations.theme === "modernello") {
    type = "modernelloContentBlock";
  } else if (
    block.type === "content" &&
    ((props.customisations.content_layout === 2 && block.col_span === 2) || (props.customisations.content_layout === 3 && block.col_span === 6))
  ) {
    type = "gridFeaturedContentBlock";
  }

  return blockComponents[type];
};
</script>
