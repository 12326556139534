<template>
  <div
    class="ag-collection"
    :class="[
      `ag-theme--${theme}`,
      `ag-mode--${mode}`,
      header_enabled && backgroundImage ? 'ag-collection--has-background' : 'ag-collection--no-background',
      `ag-partner--${data.partner.slug}`,
    ]"
  >
    <component :is="'style'" v-if="data.css">{{ data.css }}</component>
    <LazyClassicThemeStyles v-if="customisations.theme === 'default'" :customisations="customisations" :mode="mode" />
    <LazyClassicGridThemeStyles v-else-if="customisations.theme === 'classic-grid'" :customisations="customisations" :mode="mode" />
    <LazyModernelloThemeStyles v-else-if="customisations.theme === 'modernello'" :customisations="customisations" :mode="mode" />

    <LazyInspiresHeader
      v-if="customisations.header_enabled"
      :background-image="customisations.header_background_image || data.fallback_header_background_image"
      :call-to-action="data.cta?.values[data.cta?.display]"
      :contact="contact"
      :customisations="customisations"
      :logo="data.advisor.logo"
      :message="customisations.header_message"
      :partner-logo="data.partner.logo"
      :personal-message="currentPersonalisation?.personal_message"
      :personal-photo="data.advisor.personal_photo"
      :title="customisations.collection_title_enabled && collection.name"
    />

    <Band class="ag-main">
      <LazyFeaturedContentBlock
        v-if="customisations.theme === 'default' && featuredContent"
        :block="featuredContent"
        :customisations="customisations"
        :customisations-enabled="data.partner.customisations_enabled"
        :is-authorised-user="isAuthorisedUser"
        :item="featuredContent.item"
      />

      <h3 v-if="customisations.content_subtitle" class="ag-content-subtitle">{{ customisations.content_subtitle }}</h3>

      <div v-if="customisations.theme === 'default'" :class="`ag-columns--${customisations.content_layout}`" class="ag-columns">
        <div v-for="(column, index) in columns" :key="index" class="ag-columns__column">
          <Block
            v-for="block in column"
            :key="block.id"
            :block="block"
            :customisations="customisations"
            :customisations-enabled="data.partner.customisations_enabled"
            :is-authorised-user="isAuthorisedUser"
          />
        </div>
      </div>

      <div
        v-else-if="customisations.theme === 'classic-grid' || customisations.theme === 'modernello'"
        :class="`ag-columns--${customisations.content_layout}`"
        class="ag-columns ag-columns--flexible"
      >
        <Block
          v-for="block in collection.blocks"
          :key="block.id"
          :block="block"
          :customisations="customisations"
          :customisations-enabled="data.partner.customisations_enabled"
          :is-authorised-user="isAuthorisedUser"
        />
      </div>
    </Band>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    mode: Mode;
    data: LandingPage;
    currentPersonalisation: CollectionPersonalisation;
    isAuthorisedUser?: boolean;
  }>(),
  {
    isAuthorisedUser: false,
  }
);

const { collection, contact, fallback_header_background_image } = toRefs(props.data);
const { customisations } = toRefs(props.data.collection);
const { header_enabled, header_background_image, theme } = toRefs(props.data.collection.customisations);

const backgroundImage = computed(() => {
  if (header_background_image?.value) return header_background_image?.value;
  else if (fallback_header_background_image?.value) return fallback_header_background_image?.value;
  return null;
});

// Default (classic) theme only
// TODO: consider refactoring this
const { featuredContent, columns } = useTheme({
  useContent: UseContentOptions.WithSeparateFeaturedContent,
  collection: props.data.collection,
});
</script>

<style lang="postcss">
/* TODO: Refactor */
.ag-theme--default {
  .ag-block--featured-content,
  .ag-content-subtitle {
    @apply mb-10;
    @apply xl:mb-16;
  }

  .ag-block--featured-content {
    @apply md:space-x-8 lg:space-x-12 xl:space-x-20;
  }

  .ag-content-subtitle {
    @apply text-center text-xl font-bold;
  }

  .ag-columns {
    @apply grid gap-6 lg:gap-12 xl:gap-16;

    &__column .ag-block {
      & + .ag-block {
        @apply mt-6 md:mt-12;
      }

      &.ag-block--heading + .ag-block {
        @apply mt-3 md:mt-6;
      }
    }

    &--1 {
      @apply mx-auto max-w-3xl;
    }

    &--2 {
      @apply md:grid-cols-2;
    }

    &--3 {
      @apply md:grid-cols-3;
    }
  }

  .ag-block--featured-content .ag-block__media {
    @apply mb-6;
    @apply md:mb-0;
  }

  .ag-block__embed {
    @apply mt-6;
  }

  .ag-footer {
    @apply mt-8;
    @apply md:mt-12;
    @apply lg:mt-16;
  }

  &.ag-mode--landing-page,
  &.ag-mode--shared-landing-page {
    @apply flex grow flex-col border-b-8 border-t-4;
  }

  &.ag-mode--embed {
    .ag-main {
      @apply pb-0;
    }

    &.ag-collection--no-background .ag-main {
      @apply pt-6;
      @apply md:pt-8;
      @apply lg:pt-10;
    }

    .ag-header__title {
      @apply text-3xl;
    }

    .ag-block__title {
      @apply text-lg;
    }
  }
}

.ag-theme--classic-grid {
  .ag-block--featured,
  .ag-content-subtitle {
    @apply mb-8;
  }

  .ag-block--featured-content {
    .ag-block__media {
      @apply md:mr-8;
      @apply lg:mr-12;
    }

    .ag-block__embed {
      @apply mb-6;
      @apply md:mb-0;
    }
  }

  .ag-content-subtitle {
    @apply text-center text-xl font-bold;
  }

  .ag-columns {
    @apply grid gap-12;

    &--1 {
      @apply mx-auto max-w-3xl;
    }

    &--2 {
      @apply md:grid-cols-2;

      .ag-col-span-2 {
        .ag-block__content-inner {
          @apply max-w-xl;
        }
      }
    }

    &--3 {
      @apply md:grid-cols-6;

      .ag-col-span-6 {
        .ag-block__content-inner {
          @apply max-w-xl;
        }
      }
    }

    &--fixed {
      @apply auto-rows-fr;
    }

    &--flexible {
      .ag-row-span-2 {
        @apply lg:min-h-[38rem];
      }
    }
  }

  .ag-col-span-2 {
    @apply md:col-span-2;
  }

  .ag-col-span-3 {
    @apply md:col-span-3;
  }

  .ag-col-span-4 {
    @apply md:col-span-4;
  }

  .ag-col-span-6 {
    @apply md:col-span-6;
  }

  .ag-row-span-2 {
    @apply lg:row-span-2;
  }

  .ag-block__embed {
    @apply mb-4;
  }

  .ag-footer {
    @apply mt-8;
    @apply md:mt-12;
    @apply lg:mt-16;
  }

  &.ag-mode--landing-page,
  &.ag-mode--shared-landing-page {
    @apply flex grow flex-col border-b-8 border-t-4;
  }

  &.ag-mode--embed {
    .ag-main {
      @apply pb-0;
    }

    &.ag-collection--no-background .ag-main {
      @apply pt-6;
      @apply md:pt-8;
      @apply lg:pt-10;
    }

    .ag-header__title {
      @apply text-3xl;
    }

    .ag-block__title {
      @apply text-lg;
    }
  }
}

.ag-theme--modernello {
  .ag-block--featured,
  .ag-content-subtitle {
    @apply mb-8;
  }

  .ag-content-subtitle {
    @apply text-center text-xl font-bold;
  }

  .ag-columns {
    @apply grid gap-6;

    &--1 {
      @apply mx-auto max-w-3xl;
    }

    &--2 {
      @apply md:grid-cols-2;

      .ag-col-span-2 {
        .ag-block__content-inner {
          @apply max-w-xl;
        }
      }
    }

    &--3 {
      @apply md:grid-cols-6;

      .ag-col-span-6 {
        .ag-block__content-inner {
          @apply max-w-xl;
        }
      }
    }

    &--fixed {
      @apply auto-rows-fr;
    }

    &--flexible {
      .ag-row-span-2 {
        @apply lg:min-h-[38rem];
      }
    }
  }

  .ag-col-span-2 {
    @apply md:col-span-2;
  }

  .ag-col-span-3 {
    @apply md:col-span-3;
  }

  .ag-col-span-4 {
    @apply md:col-span-4;
  }

  .ag-col-span-6 {
    @apply md:col-span-6;
  }

  .ag-row-span-2 {
    @apply lg:row-span-2;
  }

  .ag-block--content,
  .ag-block--note-simple5 {
    @apply overflow-hidden rounded-lg;
  }

  .ag-block__embed {
    @apply mt-6;
  }

  .ag-footer {
    @apply mt-8;
    @apply md:mt-12;
    @apply lg:mt-16;
  }

  &.ag-mode--landing-page,
  &.ag-mode--shared-landing-page {
    @apply flex grow flex-col border-b-8 border-t-4;
  }

  &.ag-mode--embed {
    .ag-main {
      @apply pb-0;
    }

    &.ag-collection--no-background .ag-main {
      @apply pt-6;
      @apply md:pt-8;
      @apply lg:pt-10;
    }

    .ag-header__title {
      @apply text-3xl;
    }

    .ag-block__title {
      @apply text-lg;
    }
  }
}

/* @TODO: Temporary partner specific CSS. Remove later. */
.ag-partner--atlas {
  b,
  strong {
    font-weight: bold;
  }

  .ag-block--note-simple2 {
    .ag-block__title,
    .ag-block__title a,
    .ag-block__description {
      color: #403a60;
    }
  }
}
</style>
