export default function useContentWithSeparateFeatured(collection: Collection) {
  const featuredContent = computed(() => {
    return collection.customisations.featured_content_enabled ? collection.blocks[0] : null;
  });

  const columns = computed(() => {
    const blocks = [...collection.blocks];

    if (collection.customisations.featured_content_enabled) {
      blocks.splice(0, 1);
    }

    const blockCount = blocks.length;
    const blocksPerColumn = Math.ceil(blockCount / collection.customisations.content_layout);
    const parsedColumns = [];

    for (let i = 0; i < blockCount; i += blocksPerColumn) {
      parsedColumns.push(blocks.slice(i, i + blocksPerColumn));
    }

    return parsedColumns;
  });

  return {
    featuredContent,
    columns,
  };
}
