export default function useCollectionContent(collection: Collection) {
  const columns = computed(() => {
    const blocksCount = collection.blocks.length;
    const blocksPerColumn = Math.ceil(blocksCount / collection.customisations.content_layout);
    const parsedColumns = [];

    for (let i = 0; i < blocksCount; i += blocksPerColumn) {
      parsedColumns.push(collection.blocks.slice(i, i + blocksPerColumn));
    }

    return parsedColumns;
  });

  return {
    columns,
  };
}
