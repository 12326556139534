export enum UseContentOptions {
  Default = "default",
  WithSeparateFeaturedContent = "separate-featured",
}

export interface ThemeProps {
  useContent?: UseContentOptions;
  collection: Collection;
}

export function useTheme(props: ThemeProps = {}) {
  const use_content = props.useContent || UseContentOptions.Default;

  let data: {
    columns: ComputedRef<Block[][]>;
    featuredContent?: ComputedRef<Block | null>;
  };

  if (use_content === UseContentOptions.WithSeparateFeaturedContent) {
    data = useContentWithSeparateFeatured(props.collection);
  } else {
    data = useCollectionContent(props.collection);
  }

  return {
    featuredContent: data.featuredContent,
    columns: data.columns,
  };
}
